<template>
  <section class="section participants container">
    <h2 class="large-title participants__title">Наші фіналістки</h2>
    <div v-if="candidates.length <= 0">Завантаження...</div>
    <div v-else class="participants__items flex wrap jc-center">
      <CandidateComponent
        v-for="(candidate, i) in candidates"
        :key="candidate.id"
        :candidate="candidate"
        :index="i"
      />
    </div>
  </section>
</template>

<script setup>
import { onMounted, ref } from "vue";

import CandidateComponent from "./CandidateComponent.vue";

const candidates = ref([]);

onMounted(async () => {
  candidates.value = [
    // {
    //   id: 1,
    //   imageUrl: require("../assets/images/queens/q1.jpg"),
    //   fullName: "Чобану Ольга",
    //   description: "Київ",
    //   voteCount: 0,
    //   link: "",
    // },
    // {
    //   id: 3,
    //   imageUrl: require("../assets/images/queens/q3.jpg"),
    //   fullName: "Неборак Владислава",
    //   description: "Кременчук",
    //   voteCount: 709,
    //   link: "https://send.monobank.ua/jar/RXkVL5z9o",
    // },
    // {
    //   id: 4,
    //   imageUrl: require("../assets/images/queens/q4.jpg"),
    //   fullName: "Денисюк Галина",
    //   description: "Умань",
    //   voteCount: 3673,
    //   link: "https://send.monobank.ua/jar/8ghy5W3nVS",
    // },
    // {
    //   id: 5,
    //   imageUrl: require("../assets/images/queens/q5.jpg"),
    //   fullName: "Тереховська Анастасія",
    //   description: "Харків",
    //   voteCount: 1582,
    //   link: "https://send.monobank.ua/jar/9i9zEajLhb",
    // },
    // {
    //   id: 2,
    //   imageUrl: require("../assets/images/queens/q2.jpg"),
    //   fullName: "Тупичак Маріанна",
    //   description: "Львів",
    //   voteCount: 2559,
    //   link: "https://send.monobank.ua/jar/8c56ZNj8AF",
    // },
    // {
    //   id: 6,
    //   imageUrl: require("../assets/images/queens/q6.jpg"),
    //   fullName: "Темчинська Олександра",
    //   description: "Київ",
    //   voteCount: 51,
    //   link: "https://send.monobank.ua/jar/527oV1Zsq6",
    // },
    // {
    //   id: 7,
    //   imageUrl: require("../assets/images/queens/q7.jpg"),
    //   fullName: "Кулікова Лідія",
    //   description: "Одеса",
    //   voteCount: 110,
    //   link: "https://send.monobank.ua/jar/4viqJfpnZP",
    // },
    // {
    //   id: 8,
    //   imageUrl: require("../assets/images/queens/q13.jpg"),
    //   fullName: "Романенко Рімма",
    //   description: "Київ",
    //   voteCount: 11,
    //   link: "https://send.monobank.ua/jar/5j6757Z4iC",
    // },
    // {
    //   id: 9,
    //   imageUrl: require("../assets/images/queens/q9.jpg"),
    //   fullName: "Чистякова Наталі",
    //   description: "Одеса",
    //   voteCount: 76,
    //   link: "https://send.monobank.ua/jar/5Dnq6qCzji",
    // },
    // {
    //   id: 10,
    //   imageUrl: require("../assets/images/queens/q10.jpg"),
    //   fullName: "Саїнчук Ольга",
    //   description: "Київ",
    //   voteCount: 2507,
    //   link: "https://send.monobank.ua/jar/2YaSQgi8tV",
    // },
    // {
    //   id: 11,
    //   imageUrl: require("../assets/images/queens/q11.jpg"),
    //   fullName: "Плахотнюк Валентина",
    //   description: "Бахмут",
    //   voteCount: 902,
    //   link: "https://send.monobank.ua/jar/8HexLQd3kb",
    // },
    // {
    //   id: 12,
    //   imageUrl: require("../assets/images/queens/q12.jpg"),
    //   fullName: "Чернюк Олена",
    //   description: "Київ",
    //   voteCount: 332,
    //   link: "https://send.monobank.ua/jar/4jj2TniSYz",
    // },
    // {
    //   id: 14,
    //   imageUrl: require("../assets/images/queens/q14.png"),
    //   fullName: "Беломістна Наталія",
    //   description: "Харків",
    //   voteCount: 315,
    //   link: "https://send.monobank.ua/jar/7dK8VFUb3P",
    // },
    // {
    //   id: 14,
    //   imageUrl: require("../assets/images/queens/q15.jpg"),
    //   fullName: "Удовік Віталіна",
    //   description: "Київ",
    //   voteCount: 2444,
    //   link: "https://send.monobank.ua/jar/32rDJqrrwL",
    // },
    // {
    //   id: 14,
    //   imageUrl: require("../assets/images/queens/q16.jpg"),
    //   fullName: "Братіцел Василина",
    //   description: "Одеса",
    //   voteCount: 5,
    //   link: "https://send.monobank.ua/jar/2KL9xYK8xg",
    // },
    // {
    //   id: 2,
    //   imageUrl: require("../assets/images/queens/2.jpg"),
    //   fullName: "Сабріна Караушу",
    //   description:
    //     "Вітаю, мене звати Сабріна , мені 19 років, я живу в місті Чернівці.<br/>\nЯ студентка Юридичного інституту.<br/>\nВільний час проходить на самореалізацію. <br/>\nПрагну допомогти нашим захисникам України які воюють за наше майбутнє і за спокій в нашій країні. <br/>\nЯкщо проблема стала на моєму шляху як гора посеред дороги, то я шукаю обходи, інші дороги або взагалі міняю маршрут. Головне – це мета, тільки наявність мети приносить життя сенс і задоволення. Це сприяє не тільки поліпшенню здоров’я і довголіття, але також дає нам  крапельку оптимізму у важкий час. <br/>\nСлава нашим захисникам України!!!",
    //   voteCount: 0,
    //   link: "https://send.monobank.ua/jar/8TFyr635aj",
    // },
    // {
    //   id: 3,
    //   imageUrl: require("../assets/images/queens/3.png"),
    //   fullName: "Олена Ковальова",
    //   description:
    //     "Я українка! Я добра,чуйна і вродлива. До перемоги прагну через перешкоди. Хочу допогти нашим героям,хоробрим хлопцям! Довести всім,що перемоги варта кожна! Я прагну досягти успіху і бути прикладом для всіх!",
    //   voteCount: 0,
    //   link: "https://send.monobank.ua/jar/2XMtVajPmo",
    // },
    // {
    //   id: 4,
    //   imageUrl: require("../assets/images/queens/4.jpg"),
    //   fullName: "Аліна Тихенко",
    //   description:
    //     "Привіт,мене звати Аліна!<br/>\nЯ родом із Сумської області,на даний момент проживаю в місті Костопіль.<br/>\nМені 25 років.<br/>\nЧому хочу прийняти участь?<br/>\nТому що хочу показати,що навіть у такі тяжкі часи як зараз,українки сильні та незламні.<br/>\nСлава Україні!",
    //   voteCount: 220,
    //   link: "https://send.monobank.ua/jar/9X5XizEP6L",
    // },
    // {
    //   id: 5,
    //   imageUrl: require("../assets/images/queens/5.jpg"),
    //   fullName: "Дарія Вдовенко",
    //   description:
    //     "Молода бізнес леді Києва. З 10 років займалася спортом ( бойовим мистецтвом тхеквондо втф ) зайняла титул чемпіона України. Навчаюся на ветеринарному та на кінологічному відділенні в двох різних закладах. Я обожнюю виступати на сцені перед публікою. Мої головні слова по життю «Я бачу ціль і не бачу перешкод» цими словами я мотивую людей не здаватися вірити в себе та йти до своєї цілі. Так як я обожнюю тварин я люблю їм допомагати, часто допомагаю притулкам для тварин фінансово та медичною допомогою. На цей проєкт я вирішила піти с ціллю показати всюму світу які в нас українські жінки, показати своїм власним прикладом що наші жінки сильні, красиві, абіціозні, незалежні. Як наша країна.",
    //   voteCount: 23,
    //   link: "https://send.monobank.ua/jar/9ipiqtNYAn",
    // },
    // {
    //   id: 6,
    //   imageUrl: require("../assets/images/queens/6.jpg"),
    //   fullName: "Аліна Сидельник",
    //   description:
    //     "Мене звуть Аліна Сидельник, мені 20 років. <br/>\nЯ з прифронтового міста Торецька Донецької області, зараз тимчасово мешкаю у місті Вінниця. <br/>\nМрію стати відомою моделлю та учасницею конкурсів краси світового рівня. <br/>\nБрала участь у конкурсах краси «Міс Торецьк 2019» та «Топ-модель Дніпро 2023». <br/>\nРазом із модельною школою ELmodEL посіла 1 місце у щорічній київській битві модельних агенцій Fashion Awards 2021.",
    //   voteCount: 1,
    //   link: "https://send.monobank.ua/jar/RV5gZd9Up",
    // },
    // {
    //   id: 7,
    //   imageUrl: require("../assets/images/queens/7.jpg"),
    //   fullName: "Ярослава Галіцька",
    //   description:
    //     "м. Київ 39 років. Працюю в правоохоронних органах, маю юридичну освіту за спеціальністю юрист. Я не волонтер, у мене не має свого фонду. Також я люблю тварин ми іноді з сином ходимо до притулку кормити та брати гуляти з тваринами. Тож намагалась одному песику збирати кошти на лікування. На конкурс хочу за новими цікавими знайомствами.",
    //   voteCount: 0,
    //   link: "https://send.monobank.ua/jar/6wXsqkQ77",
    // },
    // {
    //   id: 8,
    //   imageUrl: require("../assets/images/queens/8.jpg"),
    //   fullName: "Анна Корнєєва",
    //   description:
    //     "м. Львів<br/>\nЗріст 167 см, вага 46 кг<br/>\nРозмір хс<br/>\n36 років<br/>\nМаю власну справу логістичні послуги по Україні та закордоном, мама трьох дітей <br/>\nЛюблю проводити час з дітьми, та обожнюю свою роботу, охоче волонтерю для потреб ЗСУ, вірю в нашу перемогу! хобі фітнес, медитації, музика, малювання",
    //   voteCount: 155,
    //   link: "https://send.monobank.ua/jar/3J2ao82gp",
    // },
    // {
    //   id: 12,
    //   imageUrl: require("../assets/images/queens/9.jpg"),
    //   fullName: "Дарина Пурдік",
    //   description:
    //     "Вітаю, друзі<br/>\nМене звати Дарина<br/>\nЯ кмітлива та завзята, впевнена в собі та фотогенічна<br/>\nЛюблю творчість, креативи та нові враження<br/>\nХочу бути корисною людям, тому навчаюся на лікаря<br/>\nТакож мрію про кар'єру моделю, тому сподіваюся на перемогу в цьому конкурсі",
    //   voteCount: 8,
    //   link: "https://send.monobank.ua/jar/8Sg16PQ3Ub",
    // },
    // {
    //   id: 13,
    //   imageUrl: require("../assets/images/queens/10.jpg"),
    //   fullName: "Тетяна Сметюк",
    //   description:
    //     "Привіт, я Тетяна Сметюк і я обожнюю життя!<br/>\nПрацюю майстром манікюру у власній студії, а також 3 роки я займаюсь спортом, спорт для мене це образ життя, нещодавно почала займатися боксом.<br/>\nДуже хочу себе спробувати у конкурсі краси адже це унікальна можливісь спробувати щось нове, буду вдячна за вашу підтримку!",
    //   voteCount: 0,
    //   link: "https://send.monobank.ua/jar/8Z8kgaC5zw",
    // },
    // {
    //   id: 14,
    //   imageUrl: require("../assets/images/queens/11.jpeg"),
    //   fullName: "Юлія Іваницька",
    //   description:
    //     "З перших днів війни я пішла добровольцем захищати нашу країну та волонтерила в харківському добробаті «Хартія».<br/>\nХоч і мрії стати солдатом перешкодило здоровʼя, та це не завадило мені отримати військову нагороду за активну волонтерську діяльність та навіть позивний) <br/>\nЩе одна моя мрія- піднімати бойовий дух піснями, адже моїм військовим позивним був «Співачка». <br/>\nЗа професією я акторка дубляжу та озвучування. <br/>\nМрію нести добро, красу та відданість. А цей конкурс та ваш голос може в цьому допомогти.<br/>\nМожна ще довго говорити про любов, добро та допомогу військовим - а можна діяти з любовʼю до них!<br/>\nPray for Ukraine🇺🇦",
    //   voteCount: 779,
    //   link: "https://send.monobank.ua/jar/9ziyMYfeQC",
    // },
    // {
    //   id: 15,
    //   imageUrl: require("../assets/images/queens/12.jpg"),
    //   fullName: "Анастасія Грушко",
    //   description:
    //     "Красуня з міста Вінниця. Працює тренером з повітряної акробатики та циркових мистецтв для дітей. Також дівчина цікавиться колекціонуванням старовинних римських срібних та золотих монет.<br/>\nУ вільний час займається волонтерством та фінансує певні потреби окремих бригад самостійно.<br/>\nТакож підтримує економіку країни допомагаючи фінанансово молодому поколінню, які почали займатись підприємницькою діяльність!<br/>\nЗ власного бюджету профінансувала автомобіль для 3-і роти 321 батальйону СТО ЗСУ Та прямо підтримує військову частину А3547 ПСЗСУ.",
    //   voteCount: 650,
    //   link: "https://send.monobank.ua/jar/3ZH4M6QSXo",
    // },
    // {
    //   id: 1,
    //   imageUrl: require("../assets/images/queens/14.jpg"),
    //   fullName: "Марта Николенко",
    //   description: "Львів",
    //   voteCount: 188,
    //   link: "https://send.monobank.ua/jar/45cCS9c51s",
    // },
    // {
    //   id: 2,
    //   imageUrl: require("../assets/images/queens/17.jpg"),
    //   fullName: "Єлизавета Тріщанович",
    //   description: "Миколаїв",
    //   voteCount: 612,
    //   link: "https://send.monobank.ua/jar/3VkGKWDKNr",
    // },
    // {
    //   id: 3,
    //   imageUrl: require("../assets/images/queens/15.jpg"),
    //   fullName: "Анастасія Браєвська",
    //   description: "Київ",
    //   voteCount: 18,
    //   link: "https://send.monobank.ua/jar/8Z8kgaC5zw",
    // },
    // {
    //   id: 4,
    //   imageUrl: require("../assets/images/queens/12.jpg"),
    //   fullName: "Анастасія Грушко",
    //   description: "Вінниця",
    //   voteCount: 3344,
    //   link: "https://send.monobank.ua/jar/6zLnTzcoor",
    // },
    // {
    //   id: 5,
    //   imageUrl: require("../assets/images/queens/16.jpg"),
    //   fullName: "Яна Мазун",
    //   description: "Овруч",
    //   voteCount: 497,
    //   link: "https://send.monobank.ua/jar/6wXsqkQ77",
    // },
    // {
    //   id: 6,
    //   imageUrl: require("../assets/images/queens/13.jpg"),
    //   fullName: "Вікторія Власенко",
    //   description: "Київ",
    //   voteCount: 2033,
    //   link: "https://send.monobank.ua/jar/7oyZqz62TY",
    // },
    // {
    //   id: 7,
    //   imageUrl: require("../assets/images/queens/18.jpg"),
    //   fullName: "Ірина Світленко",
    //   description: "Київ",
    //   voteCount: 627,
    //   link: "https://send.monobank.ua/jar/6Z2Yf8D1AR",
    // },
    // {
    //   id: 8,
    //   imageUrl: require("../assets/images/queens/5.jpg"),
    //   fullName: "Дарія Вдовенко",
    //   description: "Київ",
    //   voteCount: 11,
    //   link: "https://send.monobank.ua/jar/2EoSwUfY8J",
    // },
    // {
    //   id: 9,
    //   imageUrl: require("../assets/images/queens/11.jpeg"),
    //   fullName: "Юлія Іваницька",
    //   description: "Київ",
    //   voteCount: 55,
    //   link: "https://send.monobank.ua/jar/9n8WWqZLe7",
    // },
  ];
});
</script>
